<template>
    <div class="battle-dialog">
        <h2>{{ t('app.statek_wykryty') }}</h2>
        <p v-html="message"></p>

        <div v-if="detailsVisible">
            <div class="lookoutIcon"></div>
            <button @click="initiateAttack">{{ t('app.atakuj') }}</button>
            <button @click="sendSalute">{{ t('app.pozdrow') }}</button>
            <button @click="ignoreAction">{{ t('app.ignoruj') }}</button>
        </div>

        <div v-if="isUnderAttack">
            <div class="battleIcon"></div>
            <button @click="fightBack">{{ t('app.walcz') }}</button>
            <button @click="flee">{{ t('app.uciekaj') }}</button>
        </div>

        <div v-if="abandonCargo">
            <div class="battleIcon"></div>
            <button @click="fightBack">{{ t('app.walcz') }}</button>
            <button @click="abandonCargoAndFlee">{{ t('app.porzuc_towary_i_uciekaj') }}</button>
        </div>

        <div v-if="fleeingVisible">
            <div class="lookoutIcon"></div>
            <button @click="chaseShip">{{ t('app.scigaj') }}</button>
            <button @click="collectAbandonedCargo">{{ t('app.zbieraj_porzucone_towary') }}</button>
        </div>

        <div v-if="runTrue">
            <div class="lookoutIcon"></div>
            <button @click="ignoreAction">{{ t('app.zamknij_okno') }}</button>
        </div>

        <div v-if="catchHim">
            <div class="battleIcon"></div>
            <button @click="chaseShip(true)">{{ t('app.scigaj') }}</button>
            <button @click="ignoreAction">{{ t('app.ignoruj') }}</button>
        </div>

        <div v-if="unavoidableBattle">
            <div class="battleIcon"></div>
            <button @click="initiateAttack">{{ t('app.atakuj') }}</button>
        </div>

        <!-- Wybór strategii i amunicji -->
        <div v-if="strategySelection">
            <h3>{{ t('app.wybierz_strategie_tej_bitwy') }}</h3>
            <button @click="selectStrategy('attack')">{{ t('app.atak') }}</button>
            <button @click="selectStrategy('defense')">{{ t('app.obrona') }}</button>
            <button @click="selectStrategy('precision_shot')">{{ t('app.precyzyjny_strzal') }}</button>
            <button @click="selectStrategy('boarding')">{{ t('app.abordaz') }}</button>
        </div>

        <div v-if="selectedStrategy && !cannonReadyToShot && !selectedAmmo && battleEndOptions.length === 0">
            <h3>{{ t('app.wybierz_amunicje') }}</h3>
            <button @click="selectAmmo('cannonball')">{{ t('app.kule_armatnie') }}</button>
            <button @click="selectAmmo('chainshot')">{{ t('app.kule_lancuchowe') }}</button>
            <button @click="selectAmmo('grapeshot')">{{ t('app.kartacze') }}</button>
        </div>

        <!-- Pasek ładowania -->
        <ProgressBarComponent
            v-if="cannonReadyToShot"
            :startTime="loadingStartTime"
            :endTime="loadingEndTime"
            label = "Ładowanie armat"
        />

        <!-- Przycisk strzału po zakończeniu ładowania -->
        <div v-if="!cannonReadyToShot && selectedAmmo && battleEndOptions.length === 0">
            <button @click="fireCannon">{{ t('app.wykonac_strzal') }}</button>
        </div>

        <div v-if="battleResults" class="battleResults">
            <div v-for="round in battleResults" :key="round.round">
                <strong>Runda {{ round.round }}:</strong>
                <br>
                {{ t('app.straconych_piratow_atakujacy', {count: round.attacker_lost}) }}<br>
                <br>
                {{ t('app.straconych_piratow_obronca', {count: round.defender_lost }) }}<br>
                <br>
                {{ t('app.pozostali_atakujacy', {count: round.attacker_remaining }) }}<br>
                <br>
                {{ t('app.pozostali_obronca', {count: round.defender_remaining }) }}<br>
            </div>
        </div>
        <div v-if="battleEndOptions.length > 0">
            <button
                v-for="option in battleEndOptions"
                :key="option.action"
                @click="handleBattleEndOption(option.action)"
            >
                {{ option.textButton }}
            </button>
        </div>
    </div>
</template>
<script setup>

import {computed, getCurrentInstance, defineProps, onBeforeUnmount, onMounted, ref, watch} from 'vue';
import API from "@/utils/axios";
import ProgressBarComponent from '@/components/ProgressBarComponent.vue';
import {usePiniaStore} from "@/store/store";
import {useI18n} from "vue-i18n";

const store = usePiniaStore();
const showBattleDialog = ref(false);
const strategySelection = ref(false);
const selectedStrategy = ref(null);
const selectedAmmo = ref(null);
const detailsVisible = ref(true);
const isUnderAttack = ref(false);
const fleeingVisible = ref(false);
const abandonCargo = ref(false);
const message = ref('');
const userData = ref({});
const unavoidableBattle = ref(false);
const catchHim = ref(false);
const runTrue = ref(false);
const loadingTime = ref(0);
const cannonReadyToShot = ref(false);
const battleId = ref(0);
const loadingStartTime = ref(null);
const loadingEndTime = ref(null);
const battleEndOptions = ref([]);
const battleResults = ref(null);
const iIgnored = ref(false);
const opponentIgnored = ref(false);
const tileId = ref(0);

// eslint-disable-next-line no-unused-vars
const props = defineProps({
    detectedShip: Object
});

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(['closeBattle']);

const instance = getCurrentInstance();
const $socket = instance?.appContext.config.globalProperties.$socket;

if (!$socket) {
    console.error('$socket nie został znaleziony!');
}
const { t } = useI18n();

const opponent_id = computed(() => {
    if (!userData.value || !props.detectedShip) return null;
    return userData.value.id === props.detectedShip.first_ship_user
        ? props.detectedShip.second_ship_user
        : props.detectedShip.first_ship_user;
});

watch(
    () => props.detectedShip,
    (newVal) => {
        if (newVal?.battle_id) {
            battleId.value = newVal.battle_id;
        }
        if (newVal?.attacker_strategy) {
            if (opponent_id.value === userData.value.id) {
                selectedStrategy.value = newVal.attacker_strategy;
                strategySelection.value = true;
            }
        }
        if (newVal?.defender_strategy) {
            if (opponent_id.value === userData.value.id) {
                selectedStrategy.value = newVal.defender_strategy;
                strategySelection.value = true;
            }
        }
    },
    { immediate: true }
);

const setState = (state = false, msg = '') => {
    detailsVisible.value = false;
    isUnderAttack.value = false;
    fleeingVisible.value = false;
    abandonCargo.value = false;
    unavoidableBattle.value = false;
    catchHim.value = false;
    runTrue.value = false;
    strategySelection.value = false;
    battleEndOptions.value = [];
    if (state) {
        eval(`${state}.value = true`);
    }
    message.value = msg;
};

const selectStrategy = async (strategy) => {
    selectedStrategy.value = strategy;
    try {
        const response = await API.post('updateStrategy', {
            battle_id: battleId.value,
            user_id: userData.value.id,
            strategy: strategy
        });
        loadingTime.value = response.data.loading_time;
        console.log('loadingTime', loadingTime.value);
        setState(false, 'Strategia zapisana');
    } catch (error) {
        console.error('Błąd podczas wyboru strategii:', error);
    }
};

const selectAmmo = async (ammoType) => {
    selectedAmmo.value = ammoType;
    setState();
    // Uruchomienie progressBar
    startLoadingTimer();
};

const startLoadingTimer = () => {
    loadingStartTime.value = new Date().toISOString();
    loadingEndTime.value = new Date(Date.now() + loadingTime.value * 1000).toISOString();
    cannonReadyToShot.value = true;

    setTimeout(() => {
        cannonReadyToShot.value = false;
        $socket.emit('loadingCompleted');
    }, loadingTime.value * 1000);
};

const initiateAttack = async () => {
    console.log('send attack_ship');
    if (battleId.value === 0) {
        const response = await API.post('startBattle', {
            defender_id: opponent_id.value,
            attacker_id: userData.value.id,
            tile_id: userData.value.ship.tile_id
        });
        tileId.value = userData.value.ship.tile_id;
        battleId.value = response.data.battle_id;
    }

    $socket.emit('attack_ship', {
        attacker_id: userData.value.id,
        defender_id: opponent_id.value,
        battle_id: battleId.value,
    });

    // Sprawdzamy, czy statek ma armaty
    if (!userData.value.ship.groupedGuns || userData.value.ship.groupedGuns.length === 0) {
        // Brak armat, wyświetlamy komunikat o możliwości abordażu
        setState(false, t('app.brak_armat_battle'));
        battleEndOptions.value = [
            { action: 'boarding', textButton: t('app.abordaz') },
            { action: 'ignore', textButton: t('app.ignoruj') },
        ];
        return; // Zatrzymujemy dalszą logikę ataku
    }

    setState(false, t('app.na_stanowiska_bojowe_poczuja_moc_naszych_dzial'));
    detailsVisible.value = false;
};

const sendSalute = () => {
    $socket.emit('salute_ship', {
        sender_id: userData.value.id,
        target_id: opponent_id.value
    });
};

const fightBack = async () => {
    try {
        if (battleId.value === 0) {
            // Wysyłanie żądania POST do Laravel w celu wygenerowania battle_id
            const response = await API.post('startBattle', {
                defender_id: userData.value.id,
                attacker_id: opponent_id.value,
                tile_id: userData.value.ship.tile_id
            });
            battleId.value = response.data.battle_id;
            console.log('Battle ID otrzymane z Laravel:', response.data.battle_id);
        }

        tileId.value = userData.value.ship.tile_id;

        if (!userData.value.ship.groupedGuns || userData.value.ship.groupedGuns.length === 0) {
            // Brak armat, wyświetlamy komunikat o możliwości abordażu
            setState(false, t('app.brak_armat_battle'));
            battleEndOptions.value = [
                { action: 'boarding', textButton: t('app.abordaz') },
                { action: 'ignore', textButton: t('app.ignoruj') },
            ];
            return; // Zatrzymujemy dalszą logikę ataku
        }


        $socket.emit('fight_back', {
            defender_id: userData.value.id,
            attacker_id: opponent_id.value,
            battle_id: battleId.value,
        });

        setState('strategySelection', t('app.bitwa_rozpoczeta'));
    } catch (error) {
        console.error('Błąd podczas inicjalizacji bitwy:', error);
        alert(t('app.blad_podczas_rozpoczecia_bitwy'));
    }
};

const flee = () => {
    $socket.emit('flee_ship', {
        attacker_id: opponent_id.value
    });
    setState(false, t('app.moze_uda_sie_uciec'));
};

const abandonCargoAndFlee = () => {
    API.post('/abandonCargo').then(response => {
        console.log('message', response);
        tileId.value = response.data.tileId;
        $socket.emit('abandon_flee_ship', {
            attacker_id: opponent_id.value
        });
        setState(false, response.data.message);
    }).catch(error => {
        console.error('Błąd podczas porzucania towarów:', error);
    });

    setState(false, t('app.kapitanie_wyrzucilismy_nasze_towary_do_wody'));
};

const chaseShip = (abandonCargo) => {
    $socket.emit('chase_ship', {
        defender_id: opponent_id.value,
        abandonCargo: abandonCargo,
    });
    setState(false, t('app.zagle_na_pelen_wiatr_dopadniemy_ich_jak_burza'));
}

const collectAbandonedCargo = () => {
    API.post('collectAbandonedCargo', {
        tile_id: props.detectedShip.tile_id
    }).then(response => {
        console.log('response', response);
        if (response.data.error) {
            setState(false, response.data.error);
        } else {
            let collectedGoodsHtml = Object.entries(response.data.collectedGoods)
                .map(([product, quantity]) => `${product}: ${quantity} kg.`)
                .join('<br>');
            setState('runTrue', t('app.kapitanie_zebralismy') + `:<br>${collectedGoodsHtml}`);
        }
        iIgnored.value = true;
        $socket.emit('collect_cargo', {
            defender_id: opponent_id.value,
        });
    }).catch(error => {
        console.error('Błąd podczas zbierania towarów:', error);
    });
};

const ignoreAction = () => {
    showBattleDialog.value = false;
    setState();
    $socket.emit('ignore_battle', {
        opponent_id: opponent_id.value
    });

    iIgnored.value = true;

    // Sprawdzenie, czy obaj gracze zignorowali
    if (opponentIgnored.value) {
        endBattle(battleId.value);
        emitCloseBattle();
    }
};

const emitCloseBattle = () => {
    emit('closeBattle');
};

const fireCannon = async () => {
    try {
        const response = await API.post('fireCannon', {
            battle_id: battleId.value,
            user_id: userData.value.id,
            ammo_type: selectedAmmo.value
        });
        setState(false, response.data.message);

        if (response.data.actions.length > 0) {
            // Jeśli są dostępne opcje końcowe, wyświetl je jako przyciski
            cannonReadyToShot.value = false;
            battleEndOptions.value = response.data.actions;
        } else {
            cannonReadyToShot.value = false;
            selectedAmmo.value = null;
        }
    } catch (error) {
        console.error('Błąd podczas strzału:', error);
    }
};

const handleBattleEndOption = async (option) => {
    if (option === 'ignore') {
        ignoreAction();
    } else if (option === 'boarding') {
        console.log('option', option);
        try {
            const response = await API.post('initiateSword', {
                attacker_id: userData.value.id,
                defender_id: opponent_id.value,
                tile_id: tileId
            });
            message.value = response.data.message;
            battleId.value = response.data.battle_id;

            if (response.data.battleResults) {
                battleResults.value = response.data.battleResults;
            }

            // Obsługa dynamicznych akcji po abordażu
            if (response.data.actions) {
                battleEndOptions.value = response.data.actions.map((action) => ({
                    action: action.action,
                    textButton: action.textButton,
                }));
            }

            if (!response.data.boardingStopped) {
                // Wysyłanie przez socket
                $socket.emit('boarding_initiated', {
                    battle_id: battleId.value,
                    message: response.data.messageToDefender,
                    opponent_id: opponent_id.value
                });
            } else {
                $socket.emit('battle_end', {
                    opponent_id: opponent_id.value,
                    message: response.data.messageToDefender,
                });
                battleEndOptions.value = [];
                selectedAmmo.value = null;
                cannonReadyToShot.value = false;
                battleResults.value = null;
                setState('runTrue', response.data.message);
            }

        } catch (error) {
            console.error('Błąd podczas inicjacji abordażu:', error);
            alert(t('app.blad_inicjalizacji_abordazu'));
        }
    } else {
        // Inne akcje
        try {
            const response = await API.post('handleBattleEnd', {
                battle_id: battleId.value,
                user_id: userData.value.id,
                action: option,
            });

            message.value = response.data.message;
            if (response.data.collectedProducts) {
                let collectedGoodsHtml = Object.entries(response.data.collectedProducts)
                    .map(([product, quantity]) => `${product}: ${quantity} kg.`)
                    .join('<br>');
                setState(false, response.data.message + `:<br>${collectedGoodsHtml}`);
            }
            battleEndOptions.value = [];
            selectedAmmo.value = null;
            cannonReadyToShot.value = false;
            battleResults.value = null;
            if (response.data.battleResults) {
                battleResults.value = response.data.battleResults;
            }

            if (response.data.actions) {
                setState(false, response.data.message);
                battleEndOptions.value = response.data.actions.map((action) => ({
                    action: action.action,
                    textButton: action.textButton,
                }));
            }

            $socket.emit('battle_end', {
                opponent_id: opponent_id.value,
                message: response.data.messageToDefender,
            });
            battleEndOptions.value = [];
            selectedAmmo.value = null;
            cannonReadyToShot.value = false;
            battleResults.value = null;
            setState('runTrue', response.data.message);
        } catch (error) {
            console.error('Błąd podczas wykonywania akcji końcowej:', error);
        }
    }
};

const endBattle = async (battleId) => {
    try {
        const response = await API.post('/endBattle', { battle_id: battleId });
        console.log(response.data.message);
    } catch (error) {
        console.error('Błąd podczas zakończenia bitwy:', error);
    }
};

onMounted(() => {
    userData.value = store.getUserData;
    console.log('user data battle component', userData.value);

    if (!$socket) {
        console.error('$socket jest undefined!');
        return;
    }

    $socket.on('ship_under_attack', (data) => {
        console.log('data ship_under_attack', data);
        showBattleDialog.value = false;
        battleId.value = data.battle_id;
        setState('isUnderAttack', t('app.gracz_szykuje_sie_do_ataku'));
    });

    $socket.on('battle_started', (data) => {
        battleId.value = data.battle_id;
        console.log('data', data);
        showBattleDialog.value = false;
        setState('strategySelection', t('app.bitwa_rozpoczeta'));
    });

    $socket.on('enemy_fleeing', () => {
        console.log('data enemy_fleeing');
        showBattleDialog.value = false;
        setState('catchHim', t('app.gracza_ucieka_scigamy_go'));
    });

    $socket.on('abandon_enemy_fleeing', (data) => {
        console.log('data abandon_enemy_fleeing', data);
        showBattleDialog.value = false;
        setState('fleeingVisible', t('app.wyrzucili_towary_za_burte'));
    });

    $socket.on('chase_initiated', (data) => {
        console.log('data chase_initiated', data);
        showBattleDialog.value = false;
        setState(data.abandonCargo ? 'abandonCargo' : 'unavoidableBattle',
            data.abandonCargo ? t('app.gonia_nas') : t('app.bitwa_czas_zaczac'));
    });

    $socket.on('salute_received', (data) => {
        console.log('data salute_received', data);
        showBattleDialog.value = false;
        setState('detailsVisible', t('app.gracz_cie_pozdrowil', { player: data.data.first_ship_user }));
    });

    $socket.on('cargo_collected', (data) => {
        console.log('data cargo_collected', data);
        showBattleDialog.value = false;
        setState('runTrue', t('app.gracz_atakujacy_zebral_towary'));
    });

    $socket.on('battle_end_notification', (data) => {
        showBattleDialog.value = false;
        setState('runTrue', data.message);
    });

    $socket.on('boarding_started', (data) => {
        battleId.value = data.battle_id;
        showBattleDialog.value = false;
        setState(false, data.message);
        console.log('Abordaż rozpoczęty! Battle ID:', data.battle_id);
    });

    $socket.on('battle_ignored', () => {
        opponentIgnored.value = true;
        console.log('opponent ignoruje bitwę');
        if (iIgnored.value && opponentIgnored.value) {
            console.log('bitwa_zakonczona_przez_ignorowanie', battleId.value);
            endBattle(battleId.value);
            emitCloseBattle();
        }
    });
});

onBeforeUnmount(() => {
    console.log("Usuwam nasłuchiwanie `ship_under_attack` przed odmontowaniem komponentu.");
    const events = [
        'ship_under_attack',
        'battle_started',
        'enemy_fleeing',
        'abandon_enemy_fleeing',
        'chase_initiated',
        'salute_received',
        'cargo_collected',
        'battle_end_notification',
        'boarding_started',
        'battle_ignored'
    ];

    if ($socket) {
        events.forEach(event => $socket.off(event));
    }
});


</script>

<style scoped>
.battle-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border: 2px solid black;
    background: white;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
}
button {
    margin: 10px;
}
button:hover {
    background: #0056b3;
}
.battleIcon {
    width: 100px;
    height: 200px;
    float: left;
    background: url('@/assets/images/pirates/pirate_battle.png') no-repeat center center fixed;
    background-size: cover;
}

.lookoutIcon {
    width: 100px;
    height: 200px;
    float: left;
    background: url('@/assets/images/pirates/pirate_nest.png') no-repeat center center fixed;
    background-size: cover;
}

.battleResults {
    overflow: hidden auto;
    max-height: 200px;
}

</style>
