<script>
import { ref, onMounted, computed, watch } from 'vue';
import { usePiniaStore } from "@/store/store";
import API from "@/utils/axios";

export default {
    name: "ProgressBarComponent",
    props: {
        startTime: {
            type: String,
            required: true
        },
        endTime: {
            type: String,
            required: true
        },
        label: {
            type: String,
            default: ''
        },
        action: {
            type: String,
            default: ''
        },
        oData: {
            type: Object
        }
    },
    emits: ['endGunBuild', 'endProduction', 'endTraining'],
    setup(props, { emit }) {
        const store = usePiniaStore();
        const parseDate = (dateString) => new Date(dateString.replace(' ', 'T')).getTime();

        let animationId = null; // Identyfikator requestAnimationFrame
        const timeLeft = ref(0);
        const isVisible = ref(true);

        const updateTime = () => {
            const endTimeStamp = parseDate(props.endTime);
            const remaining = endTimeStamp - Date.now();
            timeLeft.value = Math.max(remaining, 0);

            if (timeLeft.value > 0) {
                animationId = requestAnimationFrame(updateTime);
            } else {
                isVisible.value = false;
                handleAction(props.action, props.oData);
            }
        };
        const handleAction = (action, oData) => {
            if (action === 'stopTechnology') {
                API.post('check-technology', { id: oData.id })
                    .then(() => {
                        timeLeft.value = 0;
                    });
            } else if (action === 'stopBuildGun') {
                API.post('final-build-guns', { id: oData.id })
                    .then((response) => {
                        if (response.data.status === 'in_progress') {
                            // Produkcja jeszcze trwa, ustawiamy powiadomienie i czekamy
                            setTimeout(() => handleAction(action, oData), 3000); // Ponowna próba za 3 sekundy
                        } else {
                            // Produkcja zakończona
                            timeLeft.value = 0;
                            emit('endGunBuild');
                        }
                    });
            } else if (action === 'stopProduction') {
                API.get('/check-production')
                    .then(() => {
                        console.log('checkProduction');
                        timeLeft.value = 0;
                        emit('endProduction');
                    });
            } else if (action === 'endTraining') {
                API.post(`end-training`)
                    .then((response) => {
                        if (!response.data.error) {
                            timeLeft.value = 0;
                            emit('endTraining');
                            // Wyświetl komunikat o sukcesie
                            store.setMessage(response.data.message);
                        } else {
                            store.setMessage(response.data.message);
                        }
                    });
            } else if (action === 'stopSailing') {
                store.setMessage({
                    class: 'info',
                    description: "Twój statek dopłynął do celu!"
                });
            } else if (action === 'hullRepaired') {
                API.post(`/endRepair`, { skill: 5 })
                    .then((response) => {
                        if (response.data.requestedActionCompleted) {
                            timeLeft.value = 0;
                            store.setMessage({
                                class: 'info',
                                description: "Kadłub naprawiono"
                            });
                        } else {
                            store.setMessage({
                                class: 'error',
                                description: "Naprawa kadłuba jeszcze trwa"
                            });
                        }
                    });
            } else if (action === 'sailsRepaired') {
                API.post(`/endRepair`, { skill: 6 })
                    .then((response) => {
                        if (response.data.requestedActionCompleted) {
                            timeLeft.value = 0;
                            store.setMessage({
                                class: 'info',
                                description: "Zagle naprawiono"
                            });
                        } else {
                            store.setMessage({
                                class: 'error',
                                description: "Naprawa żagli jeszcze trwa"
                            });
                        }
                    });
            } else if (action === 'crewHealthy') {
                API.post(`/endRepair`, { skill: 7 })
                    .then((response) => {
                        if (response.data.requestedActionCompleted) {
                            timeLeft.value = 0;
                            store.setMessage({
                                class: 'info',
                                description: "Załoga wyleczona"
                            });
                        } else {
                            store.setMessage({
                                class: 'error',
                                description: "Leczenie załogi jeszcze trwa"
                            });
                        }
                    });
            } else {
                console.log('Działanie zakończone dla akcji:', action);
            }
        };

        const progressPercentage = computed(() => {
            const startTimeStamp = parseDate(props.startTime);
            const endTimeStamp = parseDate(props.endTime);
            const totalDuration = endTimeStamp - startTimeStamp;
            return Math.max((timeLeft.value / totalDuration) * 100, 0);
        });

        const formattedTimeLeft = computed(() => {
            const totalSeconds = Math.floor(timeLeft.value / 1000);
            const hours = Math.floor(totalSeconds / 3600);
            const minutes = Math.floor((totalSeconds % 3600) / 60);
            const seconds = totalSeconds % 60;

            return `${props.label} ${hours.toString().padStart(2, '0')}:${minutes
                .toString()
                .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        });

        // Obserwator zmian w startTime i endTime
        watch(
            () => [props.startTime, props.endTime],
            () => {
                isVisible.value = true; // Pokaż pasek postępu
                timeLeft.value = Math.max(parseDate(props.endTime) - Date.now(), 0);
                if (animationId) {
                    cancelAnimationFrame(animationId); // Anuluj poprzednią animację
                }
                updateTime(); // Rozpocznij nową animację
            },
            { immediate: true }
        );

        onMounted(() => {
            updateTime();
        });

        return { progressPercentage, formattedTimeLeft, isVisible };
    }
};

</script>

<template>
    <div class="progress-bar-container" v-if="isVisible">
        <div class="progress-bar" :style="{ width: progressPercentage + '%' }"></div>
        <span class="progress-text">{{ formattedTimeLeft }}</span>
    </div>
</template>

<style scoped>
.progress-bar-container {
    position: relative;
    height: 20px;
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 10px;
    overflow: hidden;
}

.progress-bar {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.5s ease;
}

.progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 10px;
    color: #000000;
}

</style>
