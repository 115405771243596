<template>
    <div v-if="userData" class="user_info">
        <div
            v-tooltip :tooltip="$t('app.ustawienia')"
            @click="goToSettings()"
            class="gap_item"
        >
            <div class="avatarContainer" :class="['menu_name', userData.avatar_class]">
                <span class="username">
                    {{ userData.username }}
                </span>
            </div>
        </div>
        <div v-if="userData.averagePosition"
            v-tooltip  :tooltip="$t('app.suma_punktow_total_score', {totalScore: userData.averagePosition.totalScore})"
            @click="goToStats()"
             class="gap_item"
        >
            <div class="menu_points">
                <span class="points">
                    {{ userData.averagePosition ? userData.averagePosition.position : '-' }}
                </span>
            </div>
        </div>
        <div v-if="userData"
             v-tooltip :tooltip="$t('app.zloto')"
             class="gap_item"
        >
            <div class="menu_gold">
                <span class="gold">
                    {{ userData.golds }}
                </span>
            </div>
        </div>
        <div v-if="userData"
             v-tooltip :tooltip="$t('app.dublony')"
             class="gap_item"
        >
            <div class="menu_dublons">
                <span class="dublons">
                    {{ userData.dublons }}
                </span>
            </div>

        </div>
        <div v-if="userData"
             v-tooltip :tooltip="$t('app.perly')"
             class="gap_item"
        >
            <div class="menu_pearls">
                <span class="pearls">
                    {{ userData.pearls }}
                </span>
            </div>

        </div>
        <div v-if="userData"
             v-tooltip :tooltip="$t('app.diamenty')"
             class="gap_item"
        >
            <div class="menu_diamonds">
                <span class="diamonds">
                    {{ userData.diamonds }}
                </span>
            </div>

        </div>
        <router-link :to="{ path: '/logout' }">
            <div class="gap_item logout_continer">
                <div v-tooltip :tooltip="$t('app.wyloguj')" class="logout"></div>
            </div>
        </router-link>
    </div>
</template>

<script>

import {usePiniaStore} from "@/store/store";
import API from "@/utils/axios";

export default {
    name: 'UserInfo',
    data() {
        return {
            userData: {},
            isDataLoaded: false
        };
    },
    methods: {
        fetchUserInfoData() {
            API.get(`getUserInfoData`)
            .then(response => {
                this.userData = response.data.userInfoData;
                console.log('usewrdata', this.userData.points);
                this.isDataLoaded = true;
            })
            .catch(error => {
                console.error('Błąd podczas pobierania danych usera:', error);
            });
        },
        goToStats() {
            // Przekieruj użytkownika do statystyk
            this.$router.push('/stats');
        },
        goToSettings() {
            // Przekieruj użytkownika do ustawień
            this.$router.push('/settings');
        },
    },
    mounted() {
        this.fetchUserInfoData();
    },
    created() {
        this.store = usePiniaStore();
    },
    watch: {
        userData(newValue, oldValue) {
            if (!oldValue && newValue) {
                this.fetchUserInfoData();
                console.log('Dane zostały załadowane');
            }
        }
    }
};
</script>

<style scoped>

.user_info {
    display: flex;
    position: fixed;
    border-radius: 8px;
    height: 80px;
    flex-direction: row;
    width: 50%;
    gap: 2%;
    z-index: 9;
    left: 50%;
    transform: translateX(-50%);
    color: greenyellow;
    font-family: 'Pirata One', cursive;
    justify-content: space-around;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)), url('@/assets/images/menu_bg.png') repeat-x center center;
    background-size: cover, cover;
    background-blend-mode: overlay;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.avatarContainer, .menu_points, .menu_gold, .menu_pearls, .menu_diamonds, .menu_dublons, .logout_continer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}


.avatarContainer {
    height: 100%;
}

.username, .points, .gold, .pearls, .dublons, .diamonds, .logout {
    margin-top: auto;
    padding: 2px;
    line-height: 1;
}

.gap_item {
    height: 100%;
    width: 90px;
    font-size: 18px;
}

.menu_points {
    background: url('@/assets/images/stats_icon.png') no-repeat center center;
    background-size: cover;
    background-position: 0 5px;
    cursor: pointer;
    height: 100%;
}

.menu_name {
    height: 100%;
    cursor: pointer;
}

.menu_gold {
    background: url('@/assets/images/gold_icon.png') no-repeat center center;
    background-size: cover;
    height: 100%;
}

.menu_diamonds {
    background: url('@/assets/images/diamonds_icon.png') no-repeat center center;
    background-size: cover;
    height: 100%;
}

.menu_pearls {
    background: url('@/assets/images/pearls_icon.png') no-repeat center center;
    background-size: cover;
    height: 100%;
}

.menu_dublons {
    background: url('@/assets/images/dublons_icon.png') no-repeat center center;
    background-size: cover;
    height: 100%;
}

.logout {
    background: url('@/assets/images/logout.png') no-repeat center center;
    background-size: cover;
    width: 96%;
    height: 98%;
    align-self: center;
}

</style>
