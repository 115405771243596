<template>
    <div id="center_coord_form_area" v-if="cityData">
        <div class="form_area">
            <div class="gap_item">
                <input type="number" v-model.number="gridCoords.row" class="row_class" :placeholder="$t('app.wprowadz_numer_wiersza')" min="0" max="53" required>
            </div>
            <div class="gap_item">
                <input type="number" v-model.number="gridCoords.col" class="column_class" :placeholder="$t('app.wprowadz_numer_kolumny')" min="0" max="123" required>
            </div>
            <div class="gap_item" @click="submitGridCoords">
                <button>{{ $t('app.centruj_na_kafelku') }}</button>
            </div>
        </div>
        <div v-tooltip v-bind:tooltip="getCityDescription()" class="center_to_my_island" @click="centerToMyIsland()"></div>

        <div v-tooltip :tooltip="$t('app.centruj_na_moim_statku')" class="center_to_my_ship" @click="centerToMyShip()">
            <ProgressBarComponent
                v-if="shipTime"
                :startTime="shipTime.routeStart"
                :endTime="shipTime.routeEnd"
                label="Statek w rejsie"
                action="stopSailing"
            />
        </div>
    </div>
</template>

<script>

import { reactive, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { usePiniaStore } from '@/store/store';
import ProgressBarComponent from "@/components/ProgressBarComponent.vue";

export default {
    name: "CenterOnTile",
    components: {ProgressBarComponent},
    emits: ['center-map', 'center-island', 'center-ship'],
    props: {
        shipTime: {
            type: Object,
            required: true
        }
    },
    setup(props, { emit }) {
        const store = usePiniaStore();
        const gridCoords = reactive({ row: 0, col: 0 });
        const { t } = useI18n();

        const cityData = computed(() => {
            return store.userData?.city;
        });

        const submitGridCoords = () => {
            const coords = { x: gridCoords.row, y: gridCoords.col };
            emit('center-map', coords);
        };

        const centerToMyIsland = () => {
            emit('center-island');
        };

        const centerToMyShip = () => {
            emit('center-ship');
        };

        const getCityDescription = () => {
            return `${t('app.wyspa')} ${cityData.value?.name || ''}<br>
            ${t('app.mieszkancy')}: ${cityData.value?.citizens || 0}<br>
            ${t('app.moc_armat_na_murach')}: ${cityData.value?.guns?.length || 0}<br>
            ${t('app.poziom_bezpieczenstwa')}: ${cityData.value?.safety || 0}%`;
        };

        return {
            gridCoords,
            submitGridCoords,
            centerToMyIsland,
            centerToMyShip,
            getCityDescription,
            cityData
        };
    }
}
</script>

<style scoped>

    #center_coord_form_area {
        top: 5px;
        position: relative;
        z-index: 3;
        width: 300px;
        display: flex;
    }

    .center_to_my_island {
        background: url('@/assets/images/center_to_island.png') no-repeat center center;
        background-size: cover;
        width:75px;
        height:65px;
        cursor: pointer;
    }

    .center_to_my_ship {
        background: url('@/assets/images/center_to_ship.png') no-repeat center center;
        background-size: cover;
        width:75px;
        height:65px;
        cursor: pointer;
     }

    .form_area {
        background: url('@/assets/images/center_to_tile.png') no-repeat center center;
        background-size: cover;
        display: flex;
        flex-direction: column;
        width: 150px;
        height: 150px;
    }
    .row_class {
        width: 65px;
        background-color: transparent;
        margin-top: 25px;
        font-size: 15px;
        text-align: center;
    }

    .column_class {
        width: 65px;
        background-color: transparent;
        margin-top: 13px;
        font-size: 15px;
        text-align: center;
    }
    .gap_item button {
        cursor: pointer;
        color: greenyellow;
        background: black;
        font-family: 'Pirata One', cursive;
        padding: 2px;
        font-size: 12px;
        margin-top: 13px;

    }
</style>
