<template>
    <LoadingComponent :isLoading="isLoading"/>
    <div class="shop-page">
        <div class="shop-side">
            <div class="product-list">
                <h3>{{ $t('app.produkty_w_sklepie') }}</h3>
                <table style="width:100%">
                    <thead>
                        <tr>
                            <td>{{ $t('app.produkt') }}</td>
                            <td>{{ $t('app.stan') }}</td>
                            <td>{{ $t('app.cena_zakupu') }}</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="shopProduct in shopProducts" :key="`shop-${shopProduct.product_id}`" class="product-container">
                            <td>
                                {{ shopProduct.product_type === 'gun' ? shopProduct.name : $t('products.product_' + shopProduct.product_id) }}
                            </td>
                            <td>
                                {{ shopProduct.quantity }}
                            </td>
                            <td>
                                {{ shopProduct.product_cost }}
                            </td>
                            <td>
                                <template v-if="shipData && shipData.tile_id === parseInt(tile_id)">
                                    <button @click="decAll(shopProduct.product_id, false)">&lt;&lt;</button>
                                    <button @click="decOne(shopProduct.product_id, false)">&larr;</button>
                                    <button @click="addOne(shopProduct.product_id, false)">&rarr;</button>
                                    <button @click="addAll(shopProduct.product_id, false)">&gt;&gt;</button>
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="center-quantities">
            <div class="product-list-center">
                <table v-if="shipData && shipData.tile_id === parseInt(tile_id)" style="width:100%">
                    <thead>
                    <tr>
                        <td>
                            {{ $t('app.produkt') }}
                        </td>
                        <td>
                            {{ $t('app.w_sklepie') }}
                        </td>
                        <td>
                            {{ $t('app.na_statku') }}
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="product in allProducts" :key="`center-${product.product_id}`" class="product-quantity">
                        <td>
                            {{ product.product_type === 'gun' ? product.name : $t('products.product_' + product.product_id) }}
                        </td>
                        <td>
                            {{ findQuantity(cartProducts, product.product_id) }}
                        </td>
                        <td>
                            {{ findQuantity(sellProducts, product.product_id) }}
                        </td>
                    </tr>
                    <tr v-if="groundCity && shipData && groundCity.user_id !== shipData.user_id">
                        <td>
                        </td>
                        <td>
                            {{ $t('app.wydatek') }}: <div id="cost">{{ totalCost }}</div>
                        </td>
                        <td>
                            {{ $t('app.zarobek') }}: <div id="revenue">{{ totalRevenue }}</div>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        </td>
                        <td>
                            <button @click="doConfirmPurchase">{{ groundCity && shipData && groundCity.user_id !== shipData.user_id ? $t('app.kup_i_laduj_na_statek') : $t('app.laduj_na_statek') }}</button>
                        </td>
                        <td>
                            <button @click="doConfirmSale">{{ groundCity && shipData && this.groundCity.user_id !== shipData.user_id ? $t('app.sprzedaj_i_wyladuj_do_sklepu') : $t('app.wyladuj_do_sklepu') }}</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="ship-side">
            <div class="product-list">
                <h3>{{ $t('app.produkty_na_statku') }}</h3>

                <template v-if="shipData && shipData.tile_id === parseInt(tile_id)">
                    <table style="width:100%">
                        <thead>
                            <tr>
                                <td>{{ $t('app.produkt') }}</td>
                                <td>{{ $t('app.stan') }}</td>
                                <td>{{ $t('app.cena_sprzedazy') }}</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="shipProduct in shipProducts" :key="`ship-${shipProduct.product_id}`" class="product-container">
                                <td>
                                    <span>
                                        {{ shipProduct.product_type === 'gun' ? shipProduct.name : $t('products.product_' + shipProduct.product_id) }}
                                    </span>
                                </td>
                                <td>
                                    {{ shipProduct.quantity }}
                                </td>
                                <td>
                                    {{ shipProduct.product_cost/2 }}
                                </td>
                                <td>
                                    <button @click="addAll(shipProduct.product_id, true)">&lt;&lt;</button>
                                    <button @click="addOne(shipProduct.product_id, true)">&larr;</button>
                                    <button @click="decOne(shipProduct.product_id, true)">&rarr;</button>
                                    <button @click="decAll(shipProduct.product_id, true)">&gt;&gt;</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </template>
                <template v-else-if="shipData && shipData.tile_id !== parseInt(tile_id)">
                    {{ $t('app.aby_kupic_sprzedac_towary_musisz_wplynac_do_portu') }}
                </template>
                <template v-else>
                    {{ $t('app.udaj_sie_do_stoczni_tam_kupisz_nowy_statek') }}
                </template>
            </div>
        </div>
        <div class="toCity" @click="goToCity(tile_id)">&larr; {{ $t('app.do_miasta') }}</div>
        <div class="toShipyard" @click="goToShipyard(tile_id)">{{ $t('app.do_stoczni') }} &rarr;</div>
    </div>
</template>

<script>

import { ref } from 'vue';
import API from "@/utils/axios";
import LoadingComponent from "@/components/LoadingComponent.vue";
import {usePiniaStore} from "@/store/store";

export default {
    setup() {
        const allProducts = ref([]);
        const shipProducts = ref([]);
        const shopProducts = ref([]);
        const totalCost = ref(0);
        const totalRevenue = ref(0);

        return { allProducts, shipProducts, shopProducts, totalCost, totalRevenue };
    },
    components: {
        LoadingComponent
    },
    data() {
        return {
            tile_id: this.$route.params.tile_id,
            userData: null,
            groundCity: null,
            cartProducts: [],  // Bufor dla produktów do kupienia
            sellProducts: [],  // Bufor dla produktów do sprzedaży
            isLoading: true,
            shipData: null
        };
    },
    methods: {
        goToShipyard(tile_id) {
            // Przekieruj użytkownika na mapę
            this.$router.push('/shipyard/'+ tile_id);
        },
        goToCity(tile_id) {
            // Przekieruj użytkownika na mapę
            this.$router.push('/city/'+ tile_id);
        },
        async fetchData() {
            try {
                await Promise.all([
                    this.loadUserData(),
                    this.loadShipData(),
                    this.loadGroundCityData()
                ]);
                this.initializeAllProducts();
                this.isLoading = false;
            } catch (error) {
                console.error("Błąd podczas ładowania danych:", error);
            }
        },
        async loadUserData() {
            this.userData = this.store.getUserData;
        },
        async loadShipData() {
            return API.get(`getShipData/${this.userData.ship.id}`)
                .then(response => {
                    console.log('userData ship =', response.data.shipData);
                    if (response.data.shipData) {
                        console.log('shipData', response.data.shipData);
                        this.shipData = response.data.shipData;
                        this.shipProducts = response.data.shipData.products || [];
                    } else {
                        this.shipProducts = [];
                    }
                })
                .catch(error => {
                    console.error("Błąd podczas pobierania danych miasta:", error);
                    return null;  // Zwróć null, gdy wystąpi błąd
                });
        },
        async loadGroundCityData() {
            try {
                const groundCity = await this.getGroundCityData();

                if (groundCity && groundCity.products) {
                    this.groundCity = groundCity;
                    this.shopProducts = groundCity.products;
                } else {
                    // Obsługa pustego lub błędnego stanu
                    this.groundCity = {};
                    this.shopProducts = [];
                }
            } catch (error) {
                console.error("Błąd podczas ładowania danych miasta:", error);
                this.groundCity = {};
                this.shopProducts = [];
            }
        },
        initializeAllProducts() {
            // Połącz produkty z obu źródeł
            const combinedProducts = [...this.shipProducts, ...this.shopProducts];

            const productMap = new Map();
            combinedProducts.forEach(product => {
                if (!productMap.has(product.product_id)) {
                    productMap.set(product.product_id, product);
                }
            });

            this.allProducts = Array.from(productMap.values());
        },
        async getGroundCityData() {
            return API.get(`/city/${this.$route.params.tile_id}`)
                .then(response => {
                    if (response.data && response.data.data.city) {
                        return response.data.data.city;
                    }
                    return null;  // Zwróć null, gdy nie ma danych
                })
                .catch(error => {
                    console.error("Błąd podczas pobierania danych miasta:", error);
                    return null;  // Zwróć null, gdy wystąpi błąd
                });
        },
        addOne(productId, fromShip) {
            const product = (fromShip ? this.shipProducts : this.shopProducts).find(p => p.product_id === productId);
            if (product && product.quantity > 0) {
                const cartOrSellList = fromShip ? this.sellProducts : this.cartProducts;
                const targetProduct = cartOrSellList.find(p => p.product_id === productId);
                if (targetProduct) {
                    targetProduct.quantity += 1;
                } else {
                    cartOrSellList.push({...product, quantity: 1});
                }
                product.quantity -= 1;
                if (this.groundCity.user_id !== this.userData.ship.user_id) {
                    const productPrice = fromShip ? product.product_cost/2 : product.product_cost;
                    this.updateFinancials(productPrice, fromShip);
                }
            }
        },
        addAll(productId, fromShip) {
            const product = (fromShip ? this.shipProducts : this.shopProducts).find(p => p.product_id === productId);
            if (product && product.quantity > 0) {
                const cartOrSellList = fromShip ? this.sellProducts : this.cartProducts;
                const targetProduct = cartOrSellList.find(p => p.product_id === productId);
                if (targetProduct) {
                    targetProduct.quantity += product.quantity;
                } else {
                    cartOrSellList.push({...product, quantity: product.quantity});
                }
                if (this.groundCity.user_id !== this.userData.ship.user_id) {
                    const productPrice = fromShip ? (product.product_cost * product.quantity) / 2 : product.product_cost * product.quantity;
                    this.updateFinancials(productPrice, fromShip); // Mnożenie ceny przez ilość dla całkowitej kwoty
                }
                product.quantity = 0; // Usuń wszystkie produkty z pierwotnego miejsca
            }
        },
        decOne(productId, fromShip) {
            const cartOrSellList = fromShip ? this.sellProducts : this.cartProducts;
            const targetProduct = cartOrSellList.find(p => p.product_id === productId);
            if (targetProduct && targetProduct.quantity > 0) {
                targetProduct.quantity -= 1; // Zmniejsz ilość w buforze
                const product = (fromShip ? this.shipProducts : this.shopProducts).find(p => p.product_id === productId);
                if (product) {
                    product.quantity += 1; // Zwiększ ilość w pierwotnym miejscu
                } else {
                    // Jeśli nie ma jeszcze tego produktu w docelowej tablicy, dodaj go
                    (fromShip ? this.shipProducts : this.shopProducts).push({...targetProduct, quantity: 1});
                }
                if (this.groundCity.user_id !== this.userData.ship.user_id) {
                    const productPrice = fromShip ? targetProduct.product_cost / 2 : targetProduct.product_cost;
                    this.updateFinancials(-productPrice, fromShip); // Odejmij wartość finansową
                }
            }
        },
        decAll(productId, fromShip) {
            const cartOrSellList = fromShip ? this.sellProducts : this.cartProducts;
            const targetProduct = cartOrSellList.find(p => p.product_id === productId);
            if (targetProduct && targetProduct.quantity > 0) {
                const product = (fromShip ? this.shipProducts : this.shopProducts).find(p => p.product_id === productId);
                if (product) {
                    product.quantity += targetProduct.quantity; // Zwróć całą ilość do pierwotnej tablicy
                } else {
                    // Jeśli nie ma tego produktu, dodaj go
                    (fromShip ? this.shipProducts : this.shopProducts).push({...targetProduct, quantity: targetProduct.quantity});
                }
                const productPrice = fromShip ?
                                        (targetProduct.product_cost * targetProduct.quantity)/2 :
                                        targetProduct.product_cost * targetProduct.quantity;
                if (this.groundCity.user_id !== this.userData.ship.user_id) {
                    this.updateFinancials(-productPrice, fromShip); // Odejmij wartość finansową za wszystkie przeniesione produkty
                }
                targetProduct.quantity = 0; // Wyczyść bufor dla tego produktu
            }
        },
        updateFinancials(amount, fromShip) {
            amount = Number(amount);
            if (fromShip) {
                this.totalRevenue += amount;
            } else {
                this.totalCost += amount;
            }
        },
        findQuantity(products, productId) {
            const product = products.find(p => p.product_id === productId);
            return product ? product.quantity : 0;
        },
        addToCart(product) {
            const existingProduct = this.cartProducts.find(p => p.product_id === product.product_id);
            if (existingProduct) {
                existingProduct.quantity++;
            } else {
                this.cartProducts.push({...product, quantity: 1});
            }
        },
        addToSellList(product) {
            const existingProduct = this.sellProducts.find(p => p.product_id === product.product_id);
            if (existingProduct) {
                existingProduct.quantity++;
            } else {
                this.sellProducts.push({...product, quantity: 1});
            }
        },
        async doConfirmPurchase() {
            // metoda obsługująca zakup towarów w sklepie
            const preparedProducts = this.cartProducts.map(product => ({
                product_id: product.product_id,
                product_type: product.product_type,
                quantity: product.quantity
            }));

            const data = {
                products: preparedProducts,
                city_id: this.groundCity.id,
                ship_id: this.userData.ship.id,
                dublons: -this.totalCost,
                from_ship: false,
            };

            API.post(`updateSales`, data)
                .then(response => {
                    if (response.data.success) {
                        this.cartProducts = [];

                        // Aktualizacja shipProducts na podstawie odpowiedzi z serwera
                        this.shipProducts = response.data.ship.products;
                        this.shopProducts = response.data.city.products;
                        this.store.setCityData(response.data.city);
                        this.store.setShipData(response.data.ship);
                        this.store.setTreasureData(response.data.treasure);
                        this.totalCost = 0;
                    } else {
                        console.error('Błąd przy aktualizacji sprzedaży:', response.data.message);
                    }
                })
                .catch(error => {
                    console.error('Błąd połączenia z serwerem:', error);
                });
        },
        async doConfirmSale() {
            // Przygotowanie produktów do sprzedaży do sklepu
            const preparedProducts = this.sellProducts.map(product => ({
                product_id: product.product_id,
                quantity: product.quantity,
                product_type: product.product_type,
            }));

            const data = {
                products: preparedProducts,
                city_id: this.groundCity.id,
                ship_id: this.userData.ship.id,
                dublons: this.totalRevenue,
                from_ship: true,
            };

            API.post(`updateSales`, data)
                .then(response => {
                    if (response.data.success) {
                        this.sellProducts = [];

                        // Aktualizacja shipProducts na podstawie odpowiedzi z serwera
                        this.shipProducts = response.data.ship.products;
                        this.shopProducts = response.data.city.products;
                        this.store.setCityData(response.data.city);
                        this.store.setShipData(response.data.ship);
                        this.store.setTreasureData(response.data.treasure);
                        this.totalRevenue = 0;
                    } else {
                        console.error('Błąd przy aktualizacji sprzedaży:', response.data.message);
                    }
                })
                .catch(error => {
                    console.error('Błąd połączenia z serwerem:', error);
                });
        }
    },
    created() {
        this.store = usePiniaStore();
        this.fetchData();
    }
};
</script>

<style scoped>

.shop-page {
    position: relative;
    height: 100vh;
    width: 100%;
    background: url('@/assets/images/shop_page.png') no-repeat center center fixed;
    background-size: cover;
    display: flex;
    justify-content: space-between;
}

h3 {
    margin-bottom: 24%;
    color: #5c4033;
}

.shop-side, .ship-side {
    width: 100%;
    height:450px;
    margin-top: 5%;
    background-image: url('@/assets/images/shop_product_list_bg.png');
    background-position: center;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
}


.product-container {
    margin-bottom: 10px;
}
.center-quantities {
    width: 48%;
    height: 450px;
    display: flex;
    background-image: url('@/assets/images/center_quantities_bg.png');
    background-position: center;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    font-size: 10px;
}

.product-list-center {
    width: 61%;
    margin: 157px auto 0;
    height: 192px;
    overflow-y: auto;
}

.shop-side {
    margin-left: 5%;
}

.product-list {
    font-size: 10px;
    width: 58%;
    margin: 6% auto 0;
    height: 320px;
    overflow-y: auto;
}

button {
    font-size: 13px;
}
</style>
