// store.js

import { defineStore } from 'pinia';
import API from "@/utils/axios";

export const usePiniaStore = defineStore('main', {
    state: () => ({
        userData: null,
        gunTypes: [],
        fleetTypes: [],
        sUserLang: null,
        langTranslations: {},
        mapPieces: {},
        totalMapPieces: 16,
        isDialogueOpen: true,
        questions: null,
        technologyTree: {},
        showChat: false,
        message: {},
        activeBattle: null,
    }),
    getters: {
        getUserData: (state) => state.userData,
        getSUserLang: (state) => state.sUserLang,
        getLangTranslations: (state) => state.langTranslations,
        getGunTypes: (state) => state.gunTypes,
        getFleetTypes: (state) => state.fleetTypes,
        getTechnologyTree: (state) => state.technologyTree,
        getShowChat: (state) => state.showChat,
        getMessage: (state) => state.message,
    },
    actions: {
        closeDialogue() {
            this.isDialogueOpen = false;
        },
        openDialogue() {
            this.isDialogueOpen = true;
        },
        setUserData(sendedUserData) {
            this.userData = { ...this.userData, ...sendedUserData };
        },
        setShipData(sendedShipData) {
            if (this.userData) {
                this.userData.ship = { ...this.userData.ship, ...sendedShipData };
            }
        },
        setCityData(sendedCityData) {
            if (this.userData) {
                this.userData.city = { ...this.userData.city, ...sendedCityData };
            }
        },
        setTreasureData(sendedTreasureData) {
            if (this.userData) {
                this.userData.treasure = { ...this.userData.treasure, ...sendedTreasureData };
            }
        },
        setSUserLang(lang) {
            console.log('ustawimay lang '+ lang);
            this.sUserLang = lang;
        },
        setLangTranslations(lang) {
            this.langTranslations = lang;
            this.questions = lang.questions;
        },
        setMapPieces(pieces) {
            this.mapPieces = {};
            for (let i = 1; i <= this.totalMapPieces; i++) {
                this.mapPieces[i] = {
                    piece_number: i,
                    count: 0
                };
            }

            pieces.forEach(piece => {
                if (piece && typeof piece.piece_number === 'number' && piece.piece_number > 0) {
                    this.mapPieces[piece.piece_number] = {
                        piece_number: piece.piece_number,
                        count: piece.count
                    };
                }
            });
        },
        addMapPiece(piece) {
            if (this.mapPieces.hasOwnProperty(piece.piece_number)) {
                this.mapPieces[piece.piece_number].count += 1;
            }
        },
        setGunTypes(gunTypes) {
            this.gunTypes = gunTypes;
        },
        setFleetTypes(fleetTypes) {
            this.fleetTypes = fleetTypes;
        },
        setTechnologyTree(tree) {
            this.technologyTree = tree;
        },
        updateCityProducts(products) {
            if (this.userData && this.userData.city) {
                this.userData.city.products = products;
            }
        },
        setShowChat(status) {
            console.log(';no to emitujemy zdarzenie showChat');
            this.showChat = status;
        },
        async fetchMapPieces() {
            try {
                const response = await API.get(`/mapPieces`);
                const piecesMap = {};
                for (let i = 1; i <= 16; i++) {
                    piecesMap[i] = 0;
                }

                if (Array.isArray(response.data.pieces)) {
                    this.setMapPieces(response.data.pieces);
                } else {
                    console.error('Oczekiwano tablicy, otrzymano:', response.data.pieces);
                }
            } catch (error) {
                console.error('Error in getMapElements:', error);
            }
        },
        async collectMapPiece() {
            try {
                const response = await API.post(`collectMap`);
                this.addMapPiece(response.data.newPiece);
                return response;
            } catch (error) {
                console.error('Error sending answer:', error);
                throw error;
            }
        },
        async fetchTranslations(lang = null) {
            // Jeśli język nie jest ustawiony, pobierz preferencje przeglądarki lub domyślny
            if (!lang) {
                lang = this.sUserLang || (navigator.language && navigator.language.slice(0, 2)) || 'pl';
            }

            if (lang === this.sUserLang && Object.keys(this.langTranslations).length) {
                return;
            }

            console.log(`Pobieranie tłumaczeń dla języka: ${lang}`);
            try {
                const response = await API.get(`/translations/${lang}`);
                console.log('Tłumaczenia:', response.data);
                this.setLangTranslations(response.data);
                this.setSUserLang(lang);
            } catch (error) {
                console.error('Błąd podczas pobierania tłumaczeń:', error);
            }
        },
        async fetchGameData() {
            if (this.gunTypes.length > 0 && this.fleetTypes.length > 0) {
                return;
            }
            try {
                const response = await API.get(`/getGameData/`);
                const { gunTypes, fleetTypes } = response.data;
                this.setGunTypes(gunTypes);
                this.setFleetTypes(fleetTypes);
                console.log('Pobrano dane armat:', gunTypes);
                console.log('Pobrano dane statków:', fleetTypes);
            } catch (error) {
                console.error('Błąd podczas pobierania danych gry:', error);
            }
        },
        async fetchTechnologyTree() {
            console.log('pobieramy technologie usera');
            try {
                const response = await API.get("/technologies");
                this.setTechnologyTree(response.data.technologies);
            } catch (error) {
                console.error("Błąd podczas pobierania drzewa technologii:", error);
            }
        },
        setMessage(newMessage) {
            this.message = newMessage;
        },
        async fetchActiveBattle() {
            try {
                const response = await API.get(`activeBattle`);
                if (response.data && response.data.battle_id) {
                    this.activeBattle = {
                        battleId: response.data.battle_id,
                        attackerId: response.data.attacker_id,
                        defenderId: response.data.defender_id,
                        attacker_strategy: response.data.attacker_strategy,
                        defender_strategy: response.data.defender_strategy,
                        tile_id: response.data.tile_id,
                        status: response.data.status,
                    };
                    console.log('Pobrano aktywną bitwę:', this.activeBattle);
                } else {
                    this.activeBattle = null;
                }
            } catch (error) {
                console.error('Błąd podczas sprawdzania aktywnej bitwy:', error);
                this.activeBattle = null;
            }
        },
        clearActiveBattle() {
            this.activeBattle = null;
            console.log('Aktywna bitwa została wyczyszczona');
        },
        setActiveBattle(battleData) {
            this.activeBattle = battleData;
            console.log('Ustawiono nową aktywną bitwę:', battleData);
        },
    },
    persist: {
        storage: sessionStorage,
    },
});
