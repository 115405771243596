<template>
    <div>
        <h1>{{ $t('app.wylogowanie') }}</h1>
    </div>
</template>

<script>
import API from "@/utils/axios";
import {usePiniaStore} from "@/store/store";


export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Logout',
    mounted() {
        // Wyloguj użytkownika po zamontowaniu komponentu
        this.logoutUser();
    },
    methods: {
        async logoutUser() {
            try {
                const response = await API.post(`logout`);
                if (response.data && response.data.success) {
                    console.log('Wylogowanie udane');
                } else {
                    console.error('Wylogowanie nieudane: ' + (response.data.message || 'Brak dodatkowej wiadomości'));
                }
            } catch (error) {
                console.error('Błąd podczas wylogowywania:', error);
            } finally {
                const store = usePiniaStore();
                store.sUserLang = null;
                store.userData = null;
                await store.fetchTranslations('pl').then(() => {
                    this.$i18n.setLocaleMessage('pl', store.langTranslations);
                    this.$i18n.locale = 'pl';
                });
                store.setShowChat(null);
                sessionStorage.clear();
                localStorage.clear();

                this.$router.push('/');
            }
        }
    }
};
</script>

<style scoped>

</style>
