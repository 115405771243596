<template>
    <div v-if="message" class="background_all_screen"></div>
    <div v-if="message" class="pirate-message">
        <div class="pirate_icon" :class="message.class"></div>
        <div class="notification_area">
            <h2>{{ message.title }}</h2>
            <p v-html="message.description"></p>
            <button v-if="showNextButton" @click="nextMessage">{{ $t('app.nastepny') }}</button>
            <button @click="closeMessage">{{ $t('app.zamknij') }}</button>
        </div>
    </div>
</template>

<script>
import API from "@/utils/axios";

export default {
    name: "PirateNotification",
    data() {
        return {
            messages: [],
            currentIndex: 0,
            message: null,
        };
    },
    computed: {
        showNextButton() {
            return this.currentIndex < this.messages.length - 1;
        }
    },
    methods: {
        async fetchMessages() {
            API.get(`/messages`)
                .then(response => {
                    this.messages = response.data.data;
                    if (this.messages.length > 0) {
                        this.message = this.messages[this.currentIndex];
                    }
                })
                .catch(error => {
                    console.error('Error fetching messages:', error);
                });
        },
        async nextMessage() {
            if (this.currentIndex < this.messages.length - 1) {
                await this.markMessageAsViewed(this.message.id);
                this.currentIndex++;
                this.message = this.messages[this.currentIndex];
            }
        },
        async closeMessage() {
            if (this.message) {
                await this.markMessageAsViewed(this.message.id);
                this.message = null;
            }
        },
        async markMessageAsViewed(id) {
            API.post(`messages/${id}/viewed`)
                .then(response => {
                    if (response.data.success) {
                        console.log('wiadomość obejrzana');
                    }
                })
                .catch(error => {
                    console.error('Error marking message as viewed:', error);
                });
        }

    },
    created() {
        this.fetchMessages();
    }
};
</script>

<style>
.pirate-message {
    position: fixed;
    display: flex;
    flex-direction: row;
    left: 50%;
    font-family: 'Montserrat', sans-serif;
    transform: translateX(-50%);
    background-color: #a29d9a;
    border: 1px solid #dee2e6;
    padding: 15px;
    border-radius: 5px;
    top: 20px;
    width: 70%;
    height: 80%;
    z-index: 9;
}

.welcome_class {
    background: url('@/assets/images/pirates/pirate_notifications.png') no-repeat center center;
    background-size: contain;
    width: 50%;
    height: 100%;
}

.tutorial_class {
    background: url('@/assets/images/pirates/pirate_notifications.png') no-repeat center center;
    background-size: contain;
    width: 50%;
    height: 100%;
}

.crew_class {
    background: url('@/assets/images/pirates/pirate_notifications.png') no-repeat center center;
    background-size: contain;
    width: 50%;
    height: 100%;
}

.ship_class {
    background: url('@/assets/images/pirates/pirate_nest.png') no-repeat center center;
    background-size: contain;
    width: 50%;
    height: 100%;
    order: 2;
}

.cargo_class {
    background: url('@/assets/images/pirates/pirate_shop.png') no-repeat center center;
    background-size: contain;
    width: 50%;
    height: 100%;
}

.default_class {
    background: url('@/assets/images/pirates/pirate_notifications.png') no-repeat center center;
    background-size: contain;
    width: 50%;
    height: 100%;

}

.battle_class {
    background: url('@/assets/images/pirates/pirate_battle.png') no-repeat center center;
    background-size: contain;
    width: 50%;
    height: 100%;
    order: 2;

}

.technology_class {
    background: url('@/assets/images/pirates/pirate_technology.png') no-repeat center center;
    background-size: contain;
    width: 50%;
    height: 100%;

}

.notification_area {
    width: 50%;
    height: 100%;
}

.notification_area p {
    max-height: 63%;
    overflow-y: auto;
}

.background_all_screen {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #000;
    opacity: .4;
    z-index: 9;
}
</style>
