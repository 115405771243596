<template>
    <LoadingComponent :isLoading="isLoading"/>
    <div class="ship-page">
        <div class="ship_area">
            <div class="tabs">
                <button @click="activeTab = 'status'" :class="{ active: activeTab === 'status' }">{{ $t('app.stan_statku') }}</button>
                <button @click="activeTab = 'guns'" :class="{ active: activeTab === 'guns' }">{{ $t('app.armaty') }}</button>
                <button @click="activeTab = 'crew'" :class="{ active: activeTab === 'crew' }">{{ $t('app.szkolenie_zalogi') }}</button>
                <button @click="activeTab = 'cargo'" :class="{ active: activeTab === 'cargo' }">{{ $t('app.towary_w_ladowni') }}</button>
            </div>
            <div v-if="activeTab === 'status'">
                <h3>{{ $t('app.stan_statku') }}</h3>
                <div class="hull">
                    {{ $t('app.punkty_kadluba_statku') }}: {{ efficiency }}/{{ actEfficiency }} pkt.<br>
                    <p v-if="shipToRepair > 0  && !repairs.hull">
                        {{ $t('app.uszkodzony_kadlub') }}: {{ shipToRepair }} pkt. - <button @click="startRepair(5)">{{ $t('app.napraw_kadlub') }}</button> <br>
                    </p>
                    <div v-if="repairs.hull">
                        <ProgressBarComponent
                            :startTime="repairs.hull.startTime"
                            :endTime="repairs.hull.endTime"
                            :label="repairs.hull.label"
                            :action="repairs.hull.handleAction"
                        />
                    </div>
                </div>
                <div class="sails">
                    {{ $t('app.punkty_zagli') }}: {{ sailsCondition }}/{{ actSailsCondition }} metrów.<br>
                    <p v-if="sailsToRepair > 0  && !repairs.sails">
                        {{ $t('app.uszkodzone_zagle') }}: {{ sailsToRepair }} metrów - <button @click="startRepair(6)">{{ $t('app.napraw_zagle') }}</button> <br>
                    </p>
                    <div v-if="repairs.sails">
                        <ProgressBarComponent
                            :startTime="repairs.sails.startTime"
                            :endTime="repairs.sails.endTime"
                            :label="repairs.sails.label"
                            :action="repairs.sails.handleAction"
                        />
                    </div>
                </div>
                <br><br><br>

                <h3>{{ $t('app.stan_zalogi') }}</h3>
                <div class="crew">
                    {{ $t('app.zdrowa_zaloga') }}: {{ pirates }}/{{ activePirates }} osób<br>
                    <p v-if="piratesTreatment > 0 && !repairs.crew">
                        {{ $t('app.ranni_piraci') }}: {{ piratesTreatment }} osób - <button @click="startRepair(7)">{{ $t('app.lecz_zaloge') }}</button> <br>
                    </p>
                    <div v-if="repairs.crew">
                        <ProgressBarComponent
                            :startTime="repairs.crew.startTime"
                            :endTime="repairs.crew.endTime"
                            :label="repairs.crew.label"
                            :action="repairs.crew.handleAction"
                        />
                    </div>
                </div>
            </div>
            <div v-if="activeTab === 'guns'">
                <h3>{{ $t('app.armaty_na_wyposazeniu_statku') }}</h3>
                <div v-if="Object.keys(groupedGuns).length > 0">
                    <div v-for="(gunType, index) in groupedGuns" :key="index">
                        <div>
                            {{ gunType.quantity }} x {{ $t('guns.gun_' + gunType.dataguns_id) }}: {{ $t('app.suma_mocy') }} {{ gunType.totalDamage }}
                        </div>
                    </div>
                    <p>{{ $t('app.moc_wszystkich_armat') }}: {{ totalPowerGun }}</p>
                </div>
                <div v-else>
                    <p>{{ $t('app.brak_armat_na_wyposazeniu') }}.</p>
                </div>
                <div v-if="gunInShop.length > 0">
                    {{ $t('app.armaty_w_sklepie') }}
                    <div v-for="product in gunInShop" :key="product.id">
                        <input type="checkbox" v-model="selectedGuns[product.product_id]" :true-value="true" :false-value="false">
                        {{ $t('guns.gun_' + product.product_id) }} - {{ product.quantity }} szt. -
                        <input type="number" v-model.number="selectedQuantities[product.product_id]" :max="product.quantity" min="0">
                    </div>
                    <button @click="executeTransfer">{{ $t('app.uzbroj_statek') }}</button>
                </div>
            </div>
            <div v-if="activeTab === 'crew'" class="pirate_skills_training">
                <h3>{{ $t('app.umiejetnosci_zalogi') }}</h3>
                <div class="skills">
                    <div v-for="(skill, skill_id) in skills" :key="skill_id" class="skill">
                        <div :class="['training', 'training_' + skill_id]">
                            <div class="text_area" v-tooltip :tooltip="$t('app.training_desc_' + skill_id) + '<br> ' + $t('app.czas_szkolenia', { time: skills[skill_id].time })">
                                {{ $t('app.training_' + skill_id) }}: {{ skill.value }}
                                <!-- Sprawdzenie czy umiejętność jest w trakcie treningu -->
                                <div v-if="currentTrainingSkill && parseInt(currentTrainingSkill) === parseInt(skill_id)">
                                    <p>{{ $t('app.trening_w_toku') }}.<br></p>
                                    <TimerComponent v-if="remainingTime" :endTime="remainingTime" action="endTraining" :oData="skill" @endTraining="fetchSkills"></TimerComponent>
                                </div>
                            </div>
                            <button
                                v-if="!currentTrainingSkill"
                                class="train_skill"
                                v-tooltip
                                :tooltip="(!canAfford(skill) ? this.$t('app.nie_masz_tyle_dublonow_zdobadz_wiecej_dublonow') : '')"
                                :disabled="!canAfford(skill)"
                                :class="{ 'disabled_element': !canAfford(skill) }"
                                @click="trainSkill(skill_id)"
                            >{{ $t('app.trenuj_za_x_dublonow', {dublons: skill.cost}) }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="activeTab === 'cargo'">
                <h3>{{ $t('app.towary_w_ladowni') }}</h3>
                {{ $t('app.pojemnosc_ladowni') }}: {{ (capacity * 1000) }} kg.<br>
                <div v-if="products.length > 0">
                    <div v-for="(product, index) in products" :key="index">
                        {{ $t('products.product_' + product.product_id) }}: {{ product.quantity }} kg.
                    </div>
                </div>
                <p v-else>{{ $t('app.brak_towarow_w_ladowni') }}</p>
            </div>
        </div>
        <div v-if="hasShipInPort" class="toCity" @click="goToCity(tile_id)">&larr; {{ $t('app.do_miasta') }}</div>
        <div class="toMap" v-tooltip :tooltip="$t('app.mapa')" @click="goToMap">&larr; {{ $t('app.do_mapy') }}</div>
    </div>
</template>

<script>

import LoadingComponent from '@/components/LoadingComponent.vue';
import TimerComponent from '@/components/TimerComponent.vue';
import API from "@/utils/axios";
import {usePiniaStore} from "@/store/store";
import ProgressBarComponent from "@/components/ProgressBarComponent.vue";

export default {
    name: "ShipPage",
    components: {
        ProgressBarComponent,
        LoadingComponent,
        TimerComponent
    },
    data() {
        return {
            activeTab: 'status',
            tile_id: this.$route.params.tile_id,
            userData: null,
            shipData: null,
            groupedGuns: {},
            totalPowerGun: 0,
            isLoading: true,
            gunInShop: {},
            products: {},
            selectedGuns: {},
            selectedQuantities: {},
            hasShipInPort: false,
            skills: {},
            currentTrainingSkill: null,
            remainingTime: null,
            message: '',
            capacity: 0,
            efficiency: 0,
            actEfficiency: 0,
            sailsToRepair: 0,
            actSailsCondition: 0,
            piratesTreatment: 0,
            pirates: 0,
            activePirates: 0,
            sailsCondition: 0,
            shipToRepair: 0,
            repairs: {
                hull: null,
                sails: null,
                crew: null,
                label: '',
                handleAction: ''
            }
        };
    },
    methods: {
        async fetchData() {
            try {
                this.userData = this.store.getUserData;
                const response = await API.get('getDataToShipComponent');
                const gunInShop = response.data.gunInShop;
                if (gunInShop) {
                    this.gunInShop = gunInShop
                }
                this.products = response.data.products;
                this.groupedGuns = response.data.groupedGuns;
                this.totalPowerGun = response.data.totalPowerGun;
                this.capacity = response.data.capacity;
                this.efficiency = response.data.efficiency;
                this.actEfficiency = response.data.actEfficiency;
                this.shipToRepair = response.data.shipToRepair;
                this.actSailsCondition = response.data.actSailsCondition;
                this.sailsCondition = response.data.sailsCondition;
                this.sailsToRepair = response.data.sailsToRepair;
                this.piratesTreatment = response.data.piratesTreatment;
                this.pirates = response.data.pirates;
                this.activePirates = response.data.activePirates;
                console.log('timers', response.data.timers);
                const timers = response.data.timers.original || {}; // Pobieramy właściwy obiekt
                const activeTimers = timers.activeTimers || []; // Pobieramy aktywne timery
                activeTimers.forEach(timer => {
                    switch (timer.action) {
                        case 5:
                            this.repairs.hull = {
                                startTime: timer.startTime,
                                endTime: timer.endTime,
                                label: timer.label,
                                handleAction: timer.handleAction
                            };
                            break;
                        case 6:
                            this.repairs.sails = {
                                startTime: timer.startTime,
                                endTime: timer.endTime,
                                label: timer.label,
                                handleAction: timer.handleAction
                            };
                            break;
                        case 7:
                            this.repairs.crew = {
                                startTime: timer.startTime,
                                endTime: timer.endTime,
                                label: timer.label,
                                handleAction: timer.handleAction
                            };
                            break;
                    }
                });
            } catch (error) {
                console.error('Błąd pobierania danych:', error);
            }
            // pobieramy skille pirata
            this.fetchSkills();
            this.isLoading = false;
        },
        goToCity(tile_id) {
            // Przekieruj użytkownika do miasta
            this.$router.push('/city/' + tile_id);
        },
        addGunToShip(gunData) {
            // dodajemy armaty na wyposażenie statku
            API.post('set-new-localization-for-gun', gunData)
                .then(response => {
                    const gunInShop = response.data.gunInShop;
                    if (gunInShop) {
                        this.gunInShop = gunInShop
                    }
                    this.products = response.data.products;
                    this.groupedGuns = response.data.groupedGuns;
                    this.totalPowerGun = response.data.totalPowerGun;
                    this.capacity = response.data.capacity;
                    this.efficiency = response.data.efficiency;
                    this.sailsCondition = response.data.sailsCondition;
                });
        },
        executeTransfer() {
            const gunsToTransfer = Object.keys(this.selectedGuns)
                .filter(id => this.selectedGuns[id] && this.selectedQuantities[id] > 0)
                .map(id => ({ id, quantity: this.selectedQuantities[id] }));

            if (gunsToTransfer.length > 0) {
                this.addGunToShip({ guns: gunsToTransfer, localization: 1 });
            }
        },
        async checkShipInPort() {
            await API.get(`/ship`, {
                params: {
                    tile_id: this.tile_id
                }
            })
                .then(response => {
                    this.hasShipInPort = response.data.hasShipInPort;
                    this.fleet_id = response.data.fleet_id;
                })
                .catch(error => {
                    console.error('Błąd podczas sprawdzania statku w porcie:', error);
                });
        },
        goToMap() {
            // Przekieruj użytkownika na mapę
            this.$router.push({ path: '/map', query: { center: 'ship' } });
        },
        fetchSkills() {
            API.get('/skills')
                .then(response => {
                    this.skills = response.data.skills;
                    this.currentTrainingSkill = response.data.currentTrainingSkill;
                    this.remainingTime = response.data.remainingTime;
                    console.log('remainingTime='+ this.remainingTime);
                })
                .catch(error => {
                    console.error("There was an error fetching the skills:", error);
                });
        },
        trainSkill(skill_id) {
            API.post(`train`, {
                skill_id: skill_id
            })
                .then(response => {
                    console.log('response', response.data);
                    this.store.setMessage(response.data.message);
                    if (!response.data.error) {
                        this.currentTrainingSkill = response.data.currentTrainingSkill;
                        this.remainingTime = response.data.remainingTime;
                    }

                })
                .catch(error => {
                    console.error('Error training :', error);
                });
        },
        canAfford(skill) {
            return skill.cost < this.userData.treasure.dublons;
        },
        async startRepair(action) {
            try {
                const response = await API.post('startRepair', {
                    action: action
                });
                const params = {
                    startTime: response.data.startTime,
                    endTime: response.data.endTime,
                    label: response.data.label,
                    handleAction: response.data.handleAction
                };
                switch (action) {
                    case 5:
                        this.repairs.hull = params;
                        break;
                    case 6:
                        this.repairs.sails = params;
                        break;
                    case 7:
                        this.repairs.crew = params;
                        break;
                }
            } catch (error) {
                console.error('Błąd timera:', error);
            }
        },
    },
    mounted() {
        this.fetchData();
        this.checkShipInPort();
    },
    created() {
        this.store = usePiniaStore();
    }
}
</script>

<style scoped>
.ship-page {
    position: relative;
    height: 100vh;
    width: 100%;
    background: url('@/assets/images/ship_page.png') no-repeat center center fixed;
    background-size: cover;
    display: flex;
    justify-content: space-between;
}

.ship_area {
    position: relative;
    background: rgba(0, 0, 0, .7);
    padding: 20px;
    width: 80%;
    margin: 0 auto;
    color: greenyellow;
}

.training_1 {
    background: url('@/assets/images/training/training_1.png') no-repeat center center;
    background-size: cover;
}

.training_2 {
    background: url('@/assets/images/training/training_2.png') no-repeat center center;
    background-size: cover;
}

.training_3 {
    background: url('@/assets/images/training/training_3.png') no-repeat center center;
    background-size: cover;
}

.training_4 {
    background: url('@/assets/images/training/training_4.png') no-repeat center center;
    background-size: cover;
}

.training_5 {
    background: url('@/assets/images/training/training_5.png') no-repeat center center;
    background-size: cover;
}

.training_6 {
    background: url('@/assets/images/training/training_6.png') no-repeat center center;
    background-size: cover;
}

.training_7 {
    background: url('@/assets/images/training/training_7.png') no-repeat center center;
    background-size: cover;
}

.training_8 {
    background: url('@/assets/images/training/training_8.png') no-repeat center center;
    background-size: cover;
}

.training {
    width: 200px;
    height: 200px;
    padding: 10px;
    position: relative;
}

.text_area {
    font-size: 14px;
    background: rgba(0, 0, 0, .7);
}

.skills {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.pirate_skills_training {
    margin: 0 auto;
    overflow: hidden auto;
    height: 550px;
}

.train_skill {
    position: relative;
    top: 100px;
}
.tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.tabs button {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    background: #444;
    color: white;
    border-radius: 5px;
    margin: 0 5px;
}

.tabs button.active {
    background: #28a745;
}

.hull, .sails, .crew {
    width: 50%;
    margin: 0 auto;
}
</style>
