<template>
    <div>
        {{ $t('app.pozostaly_czas') }}: {{ formattedTime }}
    </div>
</template>

<script>
import { ref, onMounted, onUnmounted, computed } from 'vue';
import API from "@/utils/axios";
import {usePiniaStore} from "@/store/store";

export default {
    props: {
        endTime: {
            type: String, // endTime jako string w formacie 'YYYY-MM-DD HH:mm:ss'
            required: true
        },
        action: {
            type: String,
            required: true
        },
        oData: {
            type: Object
        }
    },
    emits: ['endGunBuild', 'endProduction', 'endTraining'],
    setup(props, { emit }) {

        const store = usePiniaStore();
        const parseDate = (dateString) => {
            return new Date(dateString.replace(' ', 'T')).getTime();
        };

        const endTimeStamp = parseDate(props.endTime);
        const timeLeft = ref(Math.ceil((endTimeStamp - Date.now()) / 1000));
        let timerId;

        const formatTime = (seconds) => {
            const days = Math.floor(seconds / (3600 * 24));
            const hours = Math.floor((seconds % (3600 * 24)) / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const secs = seconds % 60;

            if (days > 0) {
                return `${days}d ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
            } else if (hours > 0) {
                return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
            } else if (minutes > 0) {
                return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
            } else {
                return `${secs.toString().padStart(2, '0')}s`;
            }
        };

        const formattedTime = computed(() => formatTime(timeLeft.value));

        const updateTimeLeft = () => {
            const now = Date.now();
            const remaining = Math.ceil((endTimeStamp - now) / 1000);
            timeLeft.value = remaining > 0 ? remaining : 0;

            if (timeLeft.value > 0) {
                timerId = requestAnimationFrame(updateTimeLeft);
            } else {
                handleAction(props.action, props.oData);
            }
        };

        const handleAction = (action, oData) => {
            if (action === 'stopTechnology') {
                API.post('check-technology', { id: oData.id })
                    .then(() => {
                        timeLeft.value = 0;
                    });
            } else if (action === 'stopBuildGun') {
                API.post('final-build-guns', { id: oData.id })
                    .then(() => {
                        timeLeft.value = 0;
                        emit('endGunBuild');
                    });
            } else if (action === 'stopProduction') {
                API.get('/check-production')
                    .then(() => {
                        console.log('checkProduction');
                        timeLeft.value = 0;
                        emit('endProduction');
                    });
            } else if (action === 'endTraining') {
                API.post(`end-training`)
                    .then((response) => {
                        if (!response.data.error) {
                            timeLeft.value = 0;
                            emit('endTraining');
                            // Wyświetl komunikat o sukcesie
                            store.setMessage(response.data.message);
                        } else {
                            store.setMessage(response.data.message);
                        }
                    });
            } else {
                console.log('Działanie zakończone dla akcji:', action);
            }
        };
        onMounted(() => {
            timerId = requestAnimationFrame(updateTimeLeft);
        });

        onUnmounted(() => {
            if (timerId) {
                cancelAnimationFrame(timerId);
            }
        });

        return { formattedTime };
    }
}
</script>
